.pdf {
    width: 100%;
    overflow: hidden;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
    .back{
      position: fixed;
      right: 50px;
      top: 230px;
    }
  }
  
.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  .popup {
    background-color: #fff;
    border-radius: 10px;
    padding: 2%;
    text-align: center;
    .red{
        color:red;
    }
  }
}

.wrapper {
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  .popup {
    background-color: #fff;
    border-radius: 10px;
    padding: 2%;
    text-align: center;
    .red{
        color:red;
    }
    .withLinks{
      text-align: left;
      a{
        color: blue;
      }
    }
    .title{
      text-align: center;
      font-size: 20px;
    }
    .formGroup{
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: space-between;
      .btn{
        margin: 0 10px;
      }
      label{
        margin-right: 20px;
        line-height: 1.5em;
      }
    }
  }
}

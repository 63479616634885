.btn {
    padding: 0;
  &.alt {
    background-color: #495057;
    border-color: #495057;
    color: white;
    border-radius: 5px;
  }
  &.btnBtn {
    padding: 10px;
  }
  div,a, button {
    padding: 10px;
    display: block;
  }
}

.Admin{
    width: 100vw;
    .tabs{
        display: flex;
        padding: 20px 20px 0;
        .tab{
            padding: 5px;
            border: 1px solid black;
            border-radius: 5px 5px 0 0;
            cursor: pointer;
            border-bottom: none;
            &.active{
                color: white;
                background-color: #000;
            }
        }
    }
    .panels{
        margin: 0 20px 20px;
        border: 1px solid black;
    }
}
.aplications {
  padding: 10px;
  position: relative;
  .PDFpopup{
    width: 100%;
    height: 100%;
    padding: 1%;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    left:0;
    top:0;
    .close{
      position: absolute;
      right: 150px;
      top:3vh;
      font-size: 24px;
      padding: 5px 10px;
      color:black;
      background-color: #fff;
      border: 1px solid white;
      cursor: pointer;
    }
  }
  .PrintPatientsByDate {
    position: absolute;
    right: 50px;
    top: -45px;
  }
  .printPopupWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    .printPopup {
      position: relative;
      background-color: #fff;
      border-radius: 10px;
      padding: 2%;
      text-align: center;
      .close {
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
      }
      .title,
      input {
        margin-bottom: 10px;
      }
    }
  }
  .tableHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 50px;
    .tableTitle {
      font-size: 20px;
      font-weight: bold;
    }
    .filters {
      display: flex;
      align-items: center;
      input,
      select {
        margin: 0 10px;
      }
      input[type="checkbox"] {
        height: 20px;
        width: 20px;
        margin-bottom: -10px;
      }
      .idInput{
        width: 90px;
      }
    }
  }
  .tableWrapper {
    overflow: auto;
    height: calc(100vh - 200px);
  }
  table {
    width: 100%;
  }
  table,
  tr,
  td,
  th {
    text-align: center;
    border: 1px solid #030303;
    vertical-align: middle;
  }
  td,
  th {
    padding: 5px;
    line-height: 1.5em;
    .small {
      font-size: 12px;
    }
  }
  .btnField {
    div {
      margin: 0 auto;
    }
  }
  .paginationBtns {
    display: flex;
    max-width: 600px;
    margin: 20px auto;
    width: fit-content;
    cursor: pointer;
    .paginationBtn {
      padding: 5px;
      border: #030303 1px solid;
      margin: 0 20px;
    }
  }
  .dateGroup {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .lable {
      margin-right: 10px;
    }
  }
}
.dn {
  display: none;
  position: relative;
}
.editedField {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  .editBtn {
    position: absolute;
    cursor: pointer;
    top: -5px;
    right: -25px;
    font-size: 20px;
  }
}
.btn {
  margin: 0 auto;
  cursor: pointer;
  width: fit-content;
  &.alt {
    background-color: #495057;
    border-color: #495057;
    color: white;
    border-radius: 5px;
    padding: 10px;
  }
  &.gray {
    cursor: auto;
    background-color: lightgray;
    border-color: lightgray;
    color: gray;
  }
}

.editAplication {
  padding: 1%;
  display: flex;
  width: 100%;
  .title {
    font-size: 25px;
    margin: 20px 0;
  }
  .left {
    width: 30%;
    margin-right: 100px;
  }
  .right {
    width: calc(70% - 100px);
    table,
    tr,
    td,
    th {
      text-align: center;
      border: 1px solid #030303;
      vertical-align: middle;
    }
    td,
    th {
      padding: 5px;
    }
    table {
      width: 100%;
      padding: 10px;
      border: 1px solid black;
      margin-bottom: 20px;
      td {
        width: fit-content;
        line-height: 1.5em;
        border: 1px solid black;
        .small {
          font-size: 12px;
        }
      }
      .texarea {
        max-width: 200px;
        line-height: 1.5em;
      }
    }
  }
  .formGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 300px;
    margin-bottom: 10px;
    input[type="text"] {
      padding: 5px;
    }
    label {
      line-height: 1.5em;
      font-weight: bold;
    }
    .radio {
      margin-bottom: 10px;
      label {
        margin-left: 20px;
      }
    }
    .withLinks {
      a {
        color: blue;
      }
    }
    .editDiv {
      display: flex;
      align-items: center;
      margin-right: -50px;
      position: relative;
      .editBtn {
        position: absolute;
        cursor: pointer;
        top: -8px;
        right: -25px;
        font-size: 20px;
      }
    }
  }
  .patientCard {
    position: relative;
    width: calc(70% - 100px);
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 5px;
    .patientsBtns {
      position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      div {
        margin: 0 5px;
      }
    }
    .infoItem {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      &Title{
        font-weight: bold;
      }
      .editDiv {
        display: flex;
        align-items: center;
        margin-right: -70px;
        position: relative;
        .editBtn {
          position: absolute;
          cursor: pointer;
          top: -5px;
          right: -25px;
          font-size: 20px;
        }
      }
      &Title {
        width: 20%;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &Value {
        line-height: 1.5em;
        text-align: center;
        .small {
          font-size: 12px;
        }
      }
    }
    .dubbleInfoItem {
      display: flex;
      width: 100%;
      margin-bottom: 5px;
      .infoItem {
        margin-right: 50px;
        &Title {
          width: auto;
          margin-right: 25px;
        }
      }
    }
  }
}
.editedField {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  .editBtn {
    position: absolute;
    cursor: pointer;
    top: -5px;
    right: -25px;
    font-size: 20px;
  }
}
.btn {
  margin: 0 auto;
  cursor: pointer;
  width: fit-content;
  &.alt {
    background-color: #495057;
    border-color: #495057;
    color: white;
    border-radius: 5px;
    padding: 10px;
  }
  &.gray {
    cursor: auto;
    background-color: lightgray;
    border-color: lightgray;
    color: gray;
  }
}

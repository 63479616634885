.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  .popup {
    background-color: #fff;
    border-radius: 10px;
    padding: 2%;
    text-align: center;
    .red {
      color: red;
    }
    .title {
      text-align: center;
      font-size: 20px;
    }
    .comment {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .btns {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: space-between;
      &.comments{
        width: 360px;
        flex-wrap: wrap;
        justify-content: start;
        div{
          text-align-last: left;
          margin-bottom: 10px;
          width: 100%;
        }
      }
      input {
        margin-right: 5px;
        line-height: 1.5em;
      }
      .btn:last-child {
        margin-left: 20px;
      }
      label {
        margin-right: 20px;
      }
    }
  }
}

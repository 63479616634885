.CreateEditUser{
    padding: 1%;
    .title{
        font-size: 25px;
        margin: 20px 0;
    }
    .formGroup{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 30%;
        min-width: 300px;
        margin-bottom: 20px;
        input[type=text]{
            padding: 5px;
        }
    }

}
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1.5em;
  .client {
    position: absolute;
    top: 10px;
    left: 10%;
    width: calc(90% - 20px);
  }
  .companyCopy {
    position: absolute;
    top: calc(50% + 10px);
    left: 10%;
    width: calc(90% - 20px);
  }
  .type {
    text-align: right;
    margin-right: 50px;
    font-size: 12px;
  }
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 30px 0;
  }
  .company {
    width: fit-content;
    margin-left: auto;
  }
  .patients {
    margin-bottom: 50px;
    .patient {
      margin-bottom: 30px;
      .test {
        display: flex;
        margin-bottom: 10px;
        .testNameTitle {
          margin-right: 50px;
        }
      }
    }
  }
  .conclusion {
    margin-bottom: 80px;
  }
  .signature{
    padding-bottom: 20px;
    border-bottom: 1px dashed #000;
  }
}

.wrapper {
  position: relative;
  height: 100%;
  font-size: 16px;
  line-height: 1.5em;
  padding: 5%;
  width: 100%;
  margin: 0 auto;
  .title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 30px;
  }
  table {
    width: 100%;
    margin: 0 auto;
  }
  table,
  tr,
  td,
  th {
    text-align: center;
    border: 1px solid #030303;
    vertical-align: middle;
  }
  td,
  th {
    padding: 5px;
    line-height: 1.5em;
    .small {
      font-size: 12px;
    }
    &.memo {
      width: 400px;
    }
  }
  .products {
    display: flex;
    div {
      width: 50%;
    }
  }
}

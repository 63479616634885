.users{
    padding: 10px;
    .TableHeader {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        width: 100%;
        height: 50px;
        .left,
        .right {
          display: flex;
          align-items: center;
          .filters {
            display: flex;
            align-items: center;
            input,
            select {
              margin: 0 10px;
            }
            input[type="checkbox"] {
              height: 20px;
              width: 20px;
              margin-bottom: -10px;
            }
          }
        }
        .center{
          font-size: 20px;
          font-weight: bold;
        }
      }
      .tableWrapper {
        overflow: auto;
        height: calc(100vh - 200px);
      }
      table {
        width: 100%;
      }
      table,
      tr,
      td,
      th {
        text-align: center;
        border: 1px solid #030303;
        vertical-align: middle;
      }
      td,
      th {
        padding: 5px;
      }
      .btnField {
        div {
          margin: 0 auto;
        }
      }
      .paginationBtns {
        display: flex;
        max-width: 600px;
        margin: 20px auto;
        width: fit-content;
        .paginationBtn {
            cursor: pointer;
          padding: 5px;
          border: #030303 1px solid;
          margin: 0 20px;
        }
      }
}
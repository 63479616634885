.btn {
  margin: 0 auto;
  cursor: pointer;
  width: fit-content;
  &.alt {
    background-color: #495057;
    border-color: #495057;
    color: white;
    border-radius: 5px;
    padding: 10px;
  }
  &.gray {
    cursor: auto;
    background-color: lightgray;
    border-color: lightgray;
    color: gray;
  }
}

.header {
  border-bottom: 1px solid #495057;
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  .title{
    font-size: 32px;
    font-weight: 500;
  }
  .right {
    display: flex;
    align-items: center;
    .greeting {
      margin-right: 20px;
    }
    .btn {
      background-color: #333d4d;
      border-color: #333d4d;
      color: white;
    }
  }
}

.aplications {
  padding: 10px;
  .tableHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 50px;
    .tableTitle {
      font-size: 20px;
      font-weight: bold;
    }
    .filters {
      display: flex;
      align-items: center;
      input,
      select {
        margin: 0 10px;
      }
      input[type="checkbox"] {
        height: 20px;
        width: 20px;
        margin-bottom: -10px;
      }
    }
  }
  .tableWrapper {
    overflow: auto;
    height: calc(100vh - 200px);
  }
  table {
    width: 100%;
  }
  table,
  tr,
  td,
  th {
    text-align: center;
    border: 1px solid #030303;
    vertical-align: middle;
    line-height: 1.5em;
  }
  td,
  th {
    padding: 5px;
    .small{
      font-size: 12px;
    }
  }
  .btnField {
    div {
      margin: 0 auto;
    }
  }
  .paginationBtns {
    display: flex;
    max-width: 600px;
    margin: 20px auto;
    width: fit-content;
    cursor: pointer;
    .paginationBtn {
      padding: 5px;
      border: #030303 1px solid;
      margin: 0 20px;
    }
  }
}
.dn {
  display: none;
  position: relative;
}
.btn {
  margin: 0 auto;
  cursor: pointer;
  width: fit-content;
  &.alt {
    background-color: #495057;
    border-color: #495057;
    color: white;
    border-radius: 5px;
    padding: 10px;
  }
  &.gray {
    cursor: auto;
    background-color: lightgray;
    border-color: lightgray;
    color: gray;
  }
}

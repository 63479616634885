.AuthPage{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .logo{
        width: 100px;
        margin-bottom: 10px;
    }
    .version{
        margin-bottom: 50px;
    }
}